import React, { FC, useEffect, useRef, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import Button from "@mui/material/Button";
import { LoadingButton } from '@mui/lab';

import Container from '../../../../atoms/container';
import Modal from '../../../../atoms/modal';
import Spacing from '../../../../atoms/spacing';
import Text from '../../../../atoms/text';
import theme from '../../../../theme';
import DashboardPopMultiSelect from '../../../dashboard/filter/popMultiSelect';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../../utils/consts';

import { usePopVisualizationDownload } from "../../../../../hooks/popVisualizationDownload";
import { useBranches } from "../../../../../hooks/branches";
import { useCarcassesPops } from "../../../../../hooks/carcassesPops";
import { IBranch } from "../../../../../types/hooks/branches";
import { useBranchStore } from "../../../../../stores/branch";
import { useSelectedDateStore } from "../../../../../stores/selected_date";
import { LinearProgress, Typography } from '@mui/material';

import { API_SOCKET_URL } from '../../../../../utils/config';
let socket: WebSocket | null = null;
const CarcassesPopDownload: FC = () => {
    const refDownload = useRef<HTMLAnchorElement | null>(null);

    const {
        data,
        downloading,
        setIsModalOpen,
        downloadImages,
        isModalOpen,
        urlDownload,
        fileNameDownload,
        currentIndexDownload,
        totalDownload,
        selectedPops,
        setSelectedPops,
        downloadAllCarcassesOfDay, // Nova função para exportar todas as carcaças
    } = usePopVisualizationDownload();

    const { branches, fetchBranches } = useBranches();
    const { pops, fetchPops } = useCarcassesPops();

    const storeBranchId = useBranchStore((state) => state.branchId);
    const storeSelectedDate = useSelectedDateStore((state) => state.selectedDate);
    const [progress, setProgress] = useState<number>(0.0);
    const [progressCountActual, setProgressCountActual] = useState<number>(0);
    const [progressCountTotal, setProgressCountTotal] = useState<number>(0);
    const [downloadAll, setDownloadAll] = useState(false);
    const [downloadAllModelisOpen, setDownloadAllModelisOpen] = useState(false);

    const handleDownloadAllImages = async () => {
        try {
            const a = document.createElement('a');
            a.href = urlDownload;
            a.download = fileNameDownload;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!branches || branches.length === 0)
            fetchBranches();
        if (!pops || pops.length === 0)
            fetchPops(branches?.map(branch => branch.id!) || []);
    }, []);

    useEffect(() => {

    }, [storeBranchId, storeSelectedDate]);


    const onChangePops = (event: SelectChangeEvent<typeof selectedPops>) => {
        const { value } = event.target;
        setSelectedPops((value as string[]).sort());
    };

    useEffect(() => {
        console.log('progress', progress);
    }, [progress]);


    return (<>
        <Button
            variant='contained'
            size='large'
            disabled={downloading || data.length === 0}
            sx={{
                width: '100%',
                borderRadius: '4px',
                marginTop: 2
            }}
            onClick={() => setIsModalOpen(true)}
        >
            Exportar {data.length} {data.length === 1 ? 'imagem' : 'imagens'}
        </Button>
        <Spacing top={PADDING} />

        <Button
            variant='contained'
            size='large'
            disabled={downloading || (storeBranchId === '' || storeSelectedDate === '')}
            sx={{
                width: '100%',
                borderRadius: '4px',
                marginTop: 2
            }}
            onClick={() => {
                setDownloadAll(true);
                setDownloadAllModelisOpen(true);
                downloadAllCarcassesOfDay(refDownload, setProgress, setProgressCountActual, setProgressCountTotal);
            }} // Chama a função para exportar todas as carcaças
        >
            Exportar Todas as Carcaças do Dia
        </Button>

        <Modal isOpened={downloadAllModelisOpen} onClose={() => setDownloadAllModelisOpen(false)}>
            <Container fluid color={theme.palette.background.default} hasShadow centered borderRadius={BORDER_RADIUS}
                style={{ maxWidth: 540 }} >
                <Container inline color={theme.palette.grayLight.main} centered width="100%" borderTopRadius={BORDER_RADIUS} >
                    <Container padded >
                        <Text size={16} bold color={theme.palette.primary.light}>Exportar todas as carcaças do dia</Text>
                    </Container>
                </Container>

                <Container fluid centered spacedAround veryPadded width="90%">
                    <Text>Esse processo pode demorar um pouco dependendo da quantidade de carcaças.</Text>
                    <Spacing top={VERY_PADDING} />
               </Container>

               <Container flex centered spacedAround veryPadded width="90%">
                    <Text style={{ marginLeft: "1rem" }}> {progressCountActual} / {progressCountTotal} passos </Text>
                    <LinearProgress
                        variant="determinate"
                        value={progress * 100}
                        sx={{
                            width: '100%',
                            borderRadius: '4px',
                            marginTop: 2,
                            marginLeft: 1,
                        }}
                    />
                    <Typography variant="body2" color="textSecondary" sx = {{
                            marginLeft: 1,
                            marginTop: 1
                    }}>
                        {`${Math.round(progress * 100)}%`}
                    </Typography>
               </Container>
               <Button onClick={() => {
                    setTimeout(() => {}, 10000); //wait 10 seconds to download
                     handleDownloadAllImages();
                     setDownloadAllModelisOpen(false);
               }}
               variant='contained'
                size='large'
                sx={{
                    margin: '1rem',
                }}
               disabled={progress < 1}>
                    Clique aqui para baixar o arquivo
               </Button>
            </Container>
        </Modal>

        <Modal isOpened={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <Container fluid color={theme.palette.background.default} hasShadow centered borderRadius={BORDER_RADIUS}
                style={{ maxWidth: 540 }} >
                <Container inline color={theme.palette.grayLight.main} centered width="100%" borderTopRadius={BORDER_RADIUS} >
                    <Container padded >
                        <Text size={16} bold color={theme.palette.primary.light}>Exportar imagens no formato CVAT</Text>
                    </Container>
                </Container>

                <Container fluid centered spacedAround veryPadded width="90%">
                    <Text>Preencha o filtro para efetuar o download.</Text>
                    <Spacing top={PADDING} />
                    <Text light size={13}>Esse processo pode demorar um pouco dependendo da quantidade de imagens.</Text>
                    <Spacing top={VERY_PADDING} />
                    <DashboardPopMultiSelect
                        disabled={downloading}
                        selectedPops={selectedPops}
                        setSelectedPops={setSelectedPops}
                        onChangePops={onChangePops}
                        branches={branches.map((branch: IBranch) => branch.id!)}
                    />
                </Container>
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ opacity: downloading ? 0.5 : 1, width: 150, height: 35 }}
                        loading={downloading}
                        disabled={downloading}
                        onClick={() => setIsModalOpen(false)}>
                        <Text bold size={12} color={downloading ? theme.palette.grey[400] : theme.palette.primary.light}>Cancelar</Text>
                    </LoadingButton>
                    <Spacing left={PADDING} />
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ opacity: downloading ? 0.5 : 1, borderColor: theme.palette.primary.light, width: 150, height: 35 }}
                        loading={downloading}
                        disabled={downloading}
                        onClick={() => downloadImages(refDownload)}>
                        <Text bold size={12} color={theme.palette.background.paper}>
                            Exportar Imagens
                        </Text>
                    </LoadingButton>
                </Container>

                {downloading && currentIndexDownload > 0 &&
                    <Container fluid flex centered padded>
                        <Text medium size={12} color={theme.palette.grey[400]}>Baixando {currentIndexDownload} de {totalDownload}</Text>
                    </Container>
                }
                {currentIndexDownload > 0 && currentIndexDownload == totalDownload || downloading &&
                    <Container fluid flex centered padded>
                        <Text medium size={12} color={theme.palette.grey[400]}>Preparando arquivo para efetuar o download</Text>
                    </Container>
                }
            </Container>
        </Modal>
        <a href={urlDownload} download={fileNameDownload} className="hidden" ref={refDownload} target='_blank' />
    </>);
};

export default CarcassesPopDownload;
